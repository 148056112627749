<template>
  <div>
    <div class="autorenew-check" v-if="displayAutorenew">
      <div class="checkbox-header">Auto-renew</div>
      <input type="checkbox" id="switch" v-model="autorenew" class="checkbox" />
      <label for="switch" class="toggle"></label>
    </div>
    <div class="main-div" v-show="!showLoading && paymentMethods && paymentMethods.length">
      <div
        class="image-div payment-block"
        v-show="activePayments && activePayments.includes('PAYTM')"
        :disabled="disabledPayments && disabledPayments.includes('PAYTM')"
      >
        <div class="image-block">
          <img
            class="image"
            src="@/assets/icons/paytm.svg"
            @click="actPaytm"
            alt="paytm"
            loading="lazy"
          />
        </div>
      </div>
      <div
        class="image-div payment-block"
        v-show="activePayments && activePayments.includes('PAYPAL')"
        :disabled="disabledPayments && disabledPayments.includes('PAYPAL')"
      >
        <div class="image-block">
          <div id="paypal-button-container" class="image"></div>
        </div>
      </div>

      <div
        v-show="activePayments && activePayments.includes('RAZORPAY')"
        :disabled="disabledPayments && disabledPayments.includes('RAZORPAY')"
        class="btn image-div payment-block"
      >
        <div class="image-block">
          <button id="rzpBtn" @click="enablePayment">
            <img
              class="image"
              src="@/assets/icons/razorpay.png"
              alt="razorpay"
              loading="lazy"
            />
          </button>
        </div>
        <!-- <div id="razorbtn"></div> -->
      </div>
    </div>
    <div class='no-wallet' v-show="paymentMethods && !paymentMethods.length">
        {{noPaymentError}}
    </div>
    <form
      method="POST"
      :action="paytminitpage"
      ref="paytmSubmit"
      v-if="activePayments && activePayments.includes('PAYTM')"
    >
      <input
        v-for="(value, key) in inputDataset"
        type="hidden"
        :name="key"
        :value="value"
        :key="key"
      />
    </form>

    <mobilePopup
      v-if="mobilePopup"
      @mobileNumber="mobileNumber"
      :closePopupCB="toggleMobilePopup"
    ></mobilePopup>
    <app-loader v-show="showLoading"></app-loader>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { EventBus } from "@/main";
import appMixins from "@/mixins/appMixins";
import Vue from "vue";

export default {
  props: ["paymentData", "totalAmount"],
  data() {
    return {
      paytminitpage: "",
      inputDataset: [],
      errorMessage: "",
      page: "payment",
      showLoading: false,
      transactionID: null,
      orderID: null,
      payemntInitData: [],
      paymentError: null,
      erpiryDate: null,
      flagAutologin: true,
      mobilePopup: false,
      mobileNo: null,
      paymentMethods: null,
      activePayments: null,
      disabledPayments:[],
      autorenew: false,
      displayAutorenew: false,
      razorpayAutorenewMethods: [],
      razorpayMethods: [],
      paymentRef: null,
      noPaymentError:''
    };
  },
  computed: {
    ...mapGetters(["appConfig", "subscriberId", "appUser", "country"]),
  },
  watch: {
    autorenew(newval) {
      if (newval) {
        this.disabledPayments = this.paymentMethods
          .filter((payment) => {
            return !(
              payment.needSpecificInfo &&
              Array.isArray(payment.methods) &&
              payment.methods.some((mode) => mode.hasAutoRenewal)
            );
          })
          .map((payment) => payment["pg-provider"]);
      } else {
        this.disabledPayments=[];
      }
    },
  },
  methods: {
    ...mapActions(["actPayment", "actGetPlans", "paypalCallBack"]),
    ...mapMutations(["commitPlansSubscribedResponse"]),

    mobileNumber(value) {
      this.mobileNo = value;
      if (value) {
        this.paymentInit();
      }
    },

    toggleMobilePopup() {
      this.mobilePopup = false;
    },

    //razor pay

    async enablePayment() {
      await window.loadRazorPay();
      this.selectMethods();
      if (!this.appUser.contactno && !this.mobileNo) {
        //ask mobileno
        this.mobilePopup = true;
      } else {
        this.mobileNo = this.appUser.contactno;
        this.paymentInit();
      }
    },

    paymentInit() {
      let self = this;
      console.log("autorenew ", this.autorenew);
      this.showLoading = true;
      let params = {};
      params.gwprovider = "RAZORPAY";
      params.channel = "WEB";
      params.gatewaytype = "PG";
      if (this.displayAutorenew && this.autorenew) {
        params.autorenew = "TRUE";
      }

      if (
        this.paymentData.paymentdata.frompage === "subscribe" ||
        this.paymentData.paymentdata.frompage === "plans"
      ) {
        params.paymenttype = "SUBSCRIPTION";
        if (
          this.paymentData.paymentdata.content &&
          this.paymentData.paymentdata.content.planid
        ) {
          params.userplanid = this.paymentData.paymentdata.content.planid;
        } else if (
          this.paymentData.paymentdata.content &&
          this.paymentData.paymentdata.content.userplanid
        ) {
          params.userplanid = this.paymentData.paymentdata.content.userplanid;
        }
        params.renewperiod = this.paymentData.paymentdata.selectedtype.toUpperCase();
        params.subscriptiontype = this.paymentData.paymentdata.subscriptiontype;
        // obj.amount = this.paymentData.paymentdata.amount;
        params.amount = this.totalAmount * 100;
        params.currency = this.paymentData.paymentdata.content.currency;
      } else if (this.paymentData.paymentdata.frompage === "purchase") {
        params.paymenttype = "TICKET";
        params.contentid = this.paymentData.paymentdata.content.contentid;
        params.quality = this.paymentData.paymentdata.selectedtype;

        this.paymentData.paymentdata.content.price.forEach((element) => {
          if (element.quality === this.paymentData.paymentdata.selectedtype) {
            let tempCountry = localStorage.getItem("country");
            if (tempCountry === "IN" && element.currency === "INR") {
              params.currency = element.currency;
              params.amount = element.amount;
            } else if (element.currency === "USD" && tempCountry !== "IN") {
              params.currency = element.currency;
              params.amount = element.amount;
            }
          }
        });
      }

      this.showLoading = true;

      let localAmount = {};
      localAmount.amount = params.amount;
      localAmount.currency = params.currency;
      localStorage.setItem("amount", JSON.stringify(localAmount));

      let localFrompage = {};
      localFrompage.frompage = location.pathname;
      localStorage.setItem("frompage", JSON.stringify(localFrompage));

      this.actPayment(params)
        .then((response) => {
          this.showLoading = false;
          console.log("payment init response", response);
          if (!response.reason) {
            this.paymentRef = response.payload.paymentRef;
            this.launchRazorPay(response);
          } else {
            let data = {};
            data.errorMessage = response.reason;
            data.page = this.page;
            EventBus.$emit("failure", data);
            this.errorMessage = response.reason;
          }
        })
        .catch((error) => console.log(error));
    },
    launchRazorPay(response) {
      console.log("int respon", response);
      let self = this;

      let options = {
        key: response.payload.key,
        order_id: response.payload.order_id ? response.payload.order_id : "",
        subscription_id: response.payload.subscription_id
          ? response.payload.subscription_id
          : "",

        amount: response.payload.amount,
        currency: response.payload.currency,
        name: response.payload.name,
        description: response.payload.description,
        prefill: {
          name: response.payload.name,
          email: response.payload.email ? response.payload.email : "xxx@xxx.x",
          contact: response.payload.contactno,
          // method: response.payload.subscription_id
          //   ? self.razorpayAutorenewMethods
          //   : self.razorpayMethods,
        },
        options: {
          checkout: {
            method: response.payload.subscription_id
              ? self.razorpayAutorenewMethods
              : self.razorpayMethods,
          },
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#171717",
        },
        handler: (handlerResponse) => {
          console.log("razorpay handler response", handlerResponse);
          let params = {};
          if (handlerResponse.razorpay_order_id) {
            params.razorpay_order_id = handlerResponse.razorpay_order_id;
          } else if (handlerResponse.razorpay_subscription_id) {
            params.razorpay_subscription_id =
              handlerResponse.razorpay_subscription_id;
          }
          params.razorpay_payment_id = handlerResponse.razorpay_payment_id;
          params.razorpay_signature = handlerResponse.razorpay_signature;
          params.paymentRef = this.paymentRef;
          this.paypalCallBack(params)
            .then((data) => {
              this.showLoading = false;
              console.log("from razorpay", data);
              if (!data.error) {
                console.log("entered success");

                EventBus.$emit("planPurchased", true);
                EventBus.$emit("paymentInitPopupClose", false);
                this.$router.push({
                  name: "PaymentConfirm",
                  query: {
                    success: true,
                    transactionid: handlerResponse.razorpay_payment_id,
                  },
                });

                this.paymentAnalytics(
                  true,
                  handlerResponse.razorpay_payment_id
                );
              } else {
                EventBus.$emit("paymentInitPopupClose", false);
                this.$router.push({
                  name: "PaymentConfirm",
                  query: { error: data.reason },
                });
              }
            })
            .catch((error) => {
              this.showLoading = false;
              if (error.status === 401) {
                this.paymentError = "Session Expired.Please try again later";
              }
            });
        },
      };

      if (!options.subscription_id) {
        delete options.subscription_id;
      } else {
        delete options.order_id;
      }

      console.log("options for razorpay", options);

      let rzp1 = new Razorpay(options);

      rzp1.on("payment.failed", function (response) {
        // self.formError = resp.error.description;
        let self = this;
        let params = {};
        params.paymentRef = this.paymentRef;
        params.errordescription = response.errordescription;
        params.errorcode = response.errorcode;
        self
          .paypalCallBack(params)
          .then((data) => {
            self.showLoading = false;
            console.log("from razorpay", data);

            if (!data.error) {
              console.log("entered success");

              EventBus.$emit("paymentInitPopupClose", false);
              self.$router.push({
                name: "PaymentConfirm",
                query: { error: data.reason },
              });

              self.paymentAnalytics(true, data.reason);
            } else {
              self.$router.push({
                name: "PaymentConfirm",
                query: { error: data.reason },
              });
            }
          })
          .catch((error) => {
            self.showLoading = false;
            if (error.status === 401) {
              self.paymentError = "Session Expired.Please try again later";
            }
          });

        console.log("error", response);
      });

      // let btn = document.querySelector("#razorbtn");

      // btn.addEventListener("click", function (e) {
      rzp1.open();
      //   e.preventDefault();
      // });

      // btn.click();
    },

    selectMethods() {
      this.razorpayMethods = [];
      this.razorpayAutorenewMethods = {};
      this.appConfig.paymentMethods
        .filter((info) => info.needSpecificInfo === true)
        .forEach((info) => {
          console.log(info);
          info.methods.filter((method) => {
            if (method.hasAutoRenewal === true) {
              // this.razorpayAutorenewMethods.push(
              //   method.identifier.toLowerCase()
              // );
              this.razorpayAutorenewMethods[method.identifier] = 1;
            }
            this.razorpayMethods.push(method.identifier.toLowerCase());
          });
        });

      if (this.razorpayAutorenewMethods.length === 0) {
        this.displayAutorenew = false;
        this.autorenew = false;
      }
      console.log("renew ", this.razorpayAutorenewMethods);
      console.log("all  ", this.razorpayMethods);
    },

    //paypal payment function
    async renderPaypalButton() {
      this.showLoading = true;
      await window.loadPaypal(); //loading checkout.js file...
      let self = this;
      let data = {};

      if (self.appConfig.vendorDetails.paypal.paypalenv === "sandbox") {
        data.sandbox = self.appConfig.vendorDetails.paypal.paypalclientid;
      } else {
        data.production = self.appConfig.vendorDetails.paypal.paypalclientid;
      }
      var paypal = window.paypal;

      paypal.Button.render(
        {
          locale: "",
          country: "",
          env:
            self.appConfig.vendorDetails.paypal.paypalenv === "live" ||
            self.appConfig.vendorDetails.paypal.paypalenv === "production"
              ? "production"
              : "sandbox",
          client: data,
          commit: true,

          style: {
            size: "responsive",
            color: "white",
            shape: "rect",
            tagline: false,
            label: "paypal",
            response: true,
            height: 40,
          },

          funding: {
            allowed: [],
            disallowed: [paypal.FUNDING.CARD, paypal.FUNDING.CREDIT],
          },

          payment: function () {
            self.paymentError = null;
            let params = {};
            params.gwprovider = "PAYPAL";
            params.channel = "WEB";
            params.gatewaytype = "PG";

            if (
              self.paymentData.paymentdata.frompage === "subscribe" ||
              self.paymentData.paymentdata.frompage === "plans"
            ) {
              params.paymenttype = "SUBSCRIPTION";
              if (
                self.paymentData.paymentdata.content &&
                self.paymentData.paymentdata.content.planid
              ) {
                params.userplanid = self.paymentData.paymentdata.content.planid;
              } else if (
                self.paymentData.paymentdata.content &&
                self.paymentData.paymentdata.content.userplanid
              ) {
                params.userplanid =
                  self.paymentData.paymentdata.content.userplanid;
              }
              params.renewperiod = self.paymentData.paymentdata.selectedtype.toUpperCase();
              params.subscriptiontype =
                self.paymentData.paymentdata.subscriptiontype;
              // obj.amount = this.paymentData.paymentdata.amount;
              params.amount = self.totalAmount * 100;
              params.currency = self.paymentData.paymentdata.content.currency;
            } else if (self.paymentData.paymentdata.frompage === "purchase") {
              params.paymenttype = "TICKET";
              params.contentid = self.paymentData.paymentdata.content.contentid;
              params.quality = self.paymentData.paymentdata.selectedtype;

              // self.paymentData.paymentdata.content.price.forEach(element => {
              //   if (
              //     element.quality === self.paymentData.paymentdata.selectedtype
              //   ) {
              //     params.currency = element.currency;
              //     params.amount = element.amount;
              //   }
              // });

              self.paymentData.paymentdata.content.price.forEach((element) => {
                if (
                  element.quality === self.paymentData.paymentdata.selectedtype
                ) {
                  let tempCountry = localStorage.getItem("country");
                  if (tempCountry === "IN" && element.currency === "INR") {
                    params.currency = element.currency;
                    params.amount = element.amount;
                  } else if (
                    element.currency === "USD" &&
                    tempCountry !== "IN"
                  ) {
                    params.currency = element.currency;
                    params.amount = element.amount;
                  }
                }
              });
            }

            self.showLoading = true;

            params.intent = "sale";
            params.payer = {
              payment_method: "paypal",
            };
            params.application_context = {
              shipping_preference: "NO_SHIPPING",
            };

            let localAmount = {};
            localAmount.amount = params.amount;
            localAmount.currency = params.currency;
            localStorage.setItem("amount", JSON.stringify(localAmount));

            let localFrompage = {};
            localFrompage.frompage = location.pathname;
            localStorage.setItem("frompage", JSON.stringify(localFrompage));

            // localStorage.setItem("retry", JSON.stringify(params));

            return self
              .actPayment(params)
              .then((response) => {
                self.showLoading = false;

                if (response.success) {
                  self.transactionID = response.success;
                  self.orderID = response.payload.paypalorder.id;
                  self.payemntInitData = response;
                  return self.payemntInitData.payload.paypalorder.id;
                } else {
                  let data = {};
                  data.errorMessage = response.reason;
                  data.page = this.page;
                  EventBus.$emit("failure", data);
                  this.errorMessage = response.reason;

                  // self.paymentError = response
                  //   ? response.reason
                  //   : "Please try again later";
                  return;
                }
              })
              .catch((error) => {
                self.showLoading = false;
                if (error.status === 401) {
                  self.paymentError = "Session Expired.Please try again later";
                  // self.sessionExpiredCB();
                  // //window.location.reload()
                  window.location.assign(window.location.origin);
                }
              });
          },
          // Execute the payment
          onAuthorize: function () {
            self.paymentCallback(data, "SUCCESS");
          },
          onCancel: function () {
            self.paymentCallback(data, "CANCELLED");
          },
        },
        "#paypal-button-container"
      ).then(() => {
        console.log("paypal rendered>>>>>>>>>>>>>>");
        self.showLoading = false;
      });
    },
    paymentCallback(res, status) {
      // let url = this.payemntInitData.payload.confirmurl;
      this.showLoading = true;
      this.showPaymentButton = false;

      let params = {};
      params.url = this.payemntInitData.payload.confirmurl;

      // var form = new FormData();
      // form.append("reference_id", this.payemntInitData.payload.order_id);
      params["reference_id"] = this.payemntInitData.payload.order_id;
      if (status === "SUCCESS") {
        // form.append("orderid", this.payemntInitData.payload.paypalorder.id);
        params["orderid"] = this.payemntInitData.payload.paypalorder.id;
      }
      // form.append("status", status);
      params.status = status;

      this.paypalCallBack(params)
        .then((data) => {
          this.showLoading = false;
          console.log("from paypal", data);

          if (data.success) {
            console.log("entered success");

            EventBus.$emit("planPurchased", true);
            EventBus.$emit("paymentInitPopupClose", false);
            this.$router.push({
              name: "PaymentConfirm",
              query: {
                success: data.success,
                transactionid: data.transactionid,
              },
            });

            this.paymentAnalytics(true, data.transactionid);
          } else if (status === "CANCELLED") {
            EventBus.$emit("paymentInitPopupClose", false);
            this.$router.push({
              name: "PaymentConfirm",
              query: { error: data.error },
            });
            this.paymentAnalytics(false, data.error);
          } else if (status === "FAILED") {
            EventBus.$emit("paymentInitPopupClose", false);
            this.$router.push({
              name: "PaymentConfirm",
              query: { error: data.error },
            });
            setTimeout(() => {
              this.renderPaypalButton();
            }, 100);
            this.paymentAnalytics(false, data.error);
          } else if (data.error) {
            EventBus.$emit("paymentInitPopupClose", false);

            this.$router.push({
              name: "PaymentConfirm",
              query: { error: data.error },
            });
            setTimeout(() => {
              this.renderPaypalButton();
            }, 100);
            this.paymentAnalytics(false, data.error);
          }
        })
        .catch((error) => {
          this.showLoading = false;
          if (error.status === 401) {
            this.paymentError = "Session Expired.Please try again later";
          }
        });
    },
    paymentAnalytics(status, data) {
      let analyticsData;
      let localData = JSON.parse(localStorage.getItem("retry"));
      let localStorageAmount = JSON.parse(localStorage.getItem("amount"));
      if (localData.paymentdata.content.contentid) {
        analyticsData.ContentTitle = localData.paymentdata.content.title;
        analyticsData.ContentID = localData.paymentdata.content.contentid;
        analyticsData.Category = localData.paymentdata.content.category;
        analyticsData.ContentType = localData.paymentdata.content.contenttype;
      } else if (localData.paymentdata.content.planid) {
        analyticsData.PlanID = localData.paymentdata.content.planid;
        analyticsData.PlanName = localData.paymentdata.content.planname;
        analyticsData.PlanType = localData.paymentdata.content.plantype;
        if (localData.paymentdata.subscriptiontype === "NEW") {
          analyticsData.PurchaseType = "NORMAL";
        } else {
          analyticsData.PurchaseType = localData.paymentdata.subscriptiontype;
        }
      }
      analyticsData.Recurring = "FALSE";
      analyticsData.Expiry = this.erpiryDate;

      analyticsData.Amount = localStorageAmount.amount / 100;
      analyticsData.Currency = localStorageAmount.currency;
      analyticsData.GWProvider = "PAYPAL";

      if (localData.paymentdata.content.seriesid) {
        analyticsData.SeriesID = localData.paymentdata.content.seriesid;
        analyticsData.SeriesTitle = localData.paymentdata.content.title;
      }
      analyticsData.Quality = localData.paymentdata.content.selectedtype;

      if (status === true) {
        analyticsData["Event Label"] = "Success";
        analyticsData.PaymentStatus = "SUCCESS";
        if (localData.paymentdata.content.planid) {
          analyticsData.TransactionId = data;
        }
      } else {
        analyticsData["Event Label"] = "Failure";
        analyticsData.PaymentStatus = "FAILURE";
        analyticsData.ErrorCode = data;
        analyticsData.ErrorMessage =
          "Unfortunately your transaction has failed. Please try again";
      }
      if (localData.paymentdata.content.contentid) {
        this.ContentPurchaseEvent(analyticsData);
      } else if (localData.paymentdata.content.planid) {
        this.PlanPurchaseEvent(analyticsData);
      }
    },
    actPaytm() {
      let obj = {};
      // if (!localData) {
      obj.gwprovider = "PAYTM";
      obj.channel = "WEB";
      obj.gatewaytype = "PG";

      if (
        this.paymentData.paymentdata.frompage === "subscribe" ||
        this.paymentData.paymentdata.frompage === "plans"
      ) {
        obj.paymenttype = "SUBSCRIPTION";
        if (
          this.paymentData.paymentdata.content &&
          this.paymentData.paymentdata.content.planid
        ) {
          obj.userplanid = this.paymentData.paymentdata.content.planid;
        } else if (
          this.paymentData.paymentdata.content &&
          this.paymentData.paymentdata.content.userplanid
        ) {
          obj.userplanid = this.paymentData.paymentdata.content.userplanid;
        }
        obj.renewperiod = this.paymentData.paymentdata.selectedtype.toUpperCase();
        obj.subscriptiontype = this.paymentData.paymentdata.subscriptiontype;
        // obj.amount = this.paymentData.paymentdata.amount;
        obj.amount = this.totalAmount * 100;
        obj.currency = this.paymentData.paymentdata.content.currency;
      } else if (this.paymentData.paymentdata.frompage === "purchase") {
        obj.paymenttype = "TICKET";
        obj.contentid = this.paymentData.paymentdata.content.contentid;
        obj.quality = this.paymentData.paymentdata.selectedtype;

        this.paymentData.paymentdata.content.price.forEach((element) => {
          if (element.quality === this.paymentData.paymentdata.selectedtype) {
            let tempCountry = localStorage.getItem("country");
            if (tempCountry === "IN" && element.currency === "INR") {
              obj.currency = element.currency;
              obj.amount = element.amount;
            } else if (element.currency === "USD" && tempCountry !== "IN") {
              obj.currency = element.currency;
              obj.amount = element.amount;
            }
          }
        });
      }

      let localAmount = {};
      localAmount.amount = obj.amount;
      localAmount.currency = obj.currency;
      localStorage.setItem("amount", JSON.stringify(localAmount));

      let localFrompage = {};
      localFrompage.frompage = location.pathname;
      localStorage.setItem("frompage", JSON.stringify(localFrompage));

      // console.log("obj", obj);
      this.showLoading = true;

      this.actPayment(obj)
        .then((response) => {
          console.log("response init ", response);
          // return;
          this.showLoading = false;

          if (!response.error) {
            if (response.payload.CHECKSUMHASH) {
              this.processPaytmPayment(response.payload);
            }
            // else {
            //   window.location.href = response.success;
            // }
          } else {
            let data = {};
            data.errorMessage = response.reason;
            data.page = this.page;
            EventBus.$emit("failure", data);
            this.errorMessage = response.reason;
          }
        })
        .catch((error) => {
          console.log("error ", error);
          this.showLoading = false;

          if (error.status === 401) {
            // this.unauthorisedHandler();
            if (this.flagAutologin) {
              this.commonAutoLogin(this.actPayment);
              this.flagAutologin = false;
              return;
            }
          }
        });
    },
    processPaytmPayment(data) {
      this.paytminitpage = data.paymentinitpage;
      delete data.paymentinitpage;
      this.inputDataset = data;
      setTimeout(() => {
        this.$refs.paytmSubmit.submit();
      }, 500);
    },
  },
  mounted() {},
  created() {
    if (!(this.appConfig && this.appConfig.paymentMethods)) {
      return;
    }
    this.paymentMethods = this.appConfig.paymentMethods.filter((payment) => {
      if (payment.country.includes(this.country) ||
          (this.country!=='IN' && payment.country.includes('ROW') )
          ) {
        return true;
      } else return false;
    });
    if(!this.paymentMethods.length){
      this.noPaymentError='No Payment methods available at your location.'
    }

    this.activePayments = this.paymentMethods.map(
      (payment) => payment["pg-provider"]
    );

    if (this.activePayments.includes("PAYPAL")) {
      this.renderPaypalButton();
    }

    let hasAutoRenewalOptions=this.paymentMethods.some(info=>{
        return (info.needSpecificInfo && info.methods && info.methods.some(method=>method.hasAutoRenewal));
      })

    if (this.paymentData.paymentdata.frompage !== "purchase" && hasAutoRenewalOptions) {
      this.displayAutorenew = true;
      this.autorenew = true;
    } else {
      this.displayAutorenew = false;
      this.autorenew=false;
    }

    localStorage.setItem("retry", JSON.stringify(this.paymentData));

    EventBus.$on("RetryPayment", (data) => {
      if (data) {
        this.actPaytm();
      }
    });
  },
  components: {
    "app-loader": () =>
      import(
        /* webpackChunkName: "appLoader" */ "@/components/Shared/templates/appLoader.vue"
      ),
    mobilePopup: () =>
      import(
        /* webpackChunkName: "mobilePopup" */ "@/components/Popups/mobileNoPopup.vue"
      ),
  },
  mixins: [appMixins],
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";

.main-div {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  .image-div {
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 10px;
    width: 154px;
    .image-block {
      position: relative;
      height: 40px;
      width: 154px;
      transition:transform 0.2s;
      &:hover{
        transform:scale(1.05);
      }
      .image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
      }
    }

    &.payment-block[disabled="disabled"]{
      pointer-events: none;
      opacity:0.5;
    }
  }
}

.autorenew-check {
  $checkbox-height: clamp(10px, 1vw, 24px);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 2vw;
  height: 58px;
  @include breakpoint(max480) {
    top: 30px;
    left: 2vw;
    right: auto;
    height: 30px;
  }
  .checkbox-header {
    font-family: $font-family;
    font-weight: $font-weight-semibold;
    font-size: 1rem;
    margin-right: 5px;
  }
  input.checkbox {
    height: 0;
    width: 0;
    visibility: hidden;

    &:checked + label {
      background: var(--primary-clr);
    }
    &:checked + label:after {
      left: 100%;
      transform: translate(-100%, -50%);
    }
  }

  label.toggle {
    cursor: pointer;
    width: calc(3 * #{$checkbox-height});
    height: $checkbox-height;
    background: grey;
    display: block;
    border-radius: calc(1 / 2 * #{$checkbox-height});
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: calc(1.5 * #{$checkbox-height});
      height: calc(1.5 * #{$checkbox-height});
      background: #fff;
      border-radius: 50%;
      transition: 0.3s;
      transform: translate(0%, -50%);
    }
  }
}
.no-wallet{
  font-family:$font-family;
  font-size:1rem;
  line-height:1.5rem;
  font-weight:$font-weight-regular;
}
</style>